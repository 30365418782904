<template>
  <div id="win">
    <BackToSpaceButton />
    <div class="relative bg-white rounded-lg p-2 text-black text-shadow">
      <div class="geminis">
        <h2 class="text-5xl xl:text-8xl">
          Bravo !
        </h2>
        <p class="text-xl -mt-2">
          Tu as remporté cette <br>
          bataille intergalactique
        </p>
      </div>
      <div>
        <p class="text-4xl font-bold w-1/2">
          Souhaites-tu repartir à l’assaut ?
        </p>
      </div>
      <img
        class="absolute w-2/3 top-0 -right-44"
        :data-src="require('@/assets/images/SpaceCode_AstroWin.svg')"
      >
    </div>
    <div>
      <EndButtons></EndButtons>
    </div>
  </div>
</template>

<script>
import BackToSpaceButton from "@/components/buttons/BackToSpaceButton.vue";
import EndButtons from "../Games/gameOver/EndButtons.vue";

export default {
  name: "Win",
  components: {EndButtons, BackToSpaceButton},
};
</script>

<style scoped>
</style>
