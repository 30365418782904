<template>
  <div class="flex p-1.5 ml-1 space-x-4">
    <button
      class="w-full bg-space-green text-center rounded-full border-none relative text-white text-xl font-bold px-4 py-2 transition duration-150 hover:bg-space-darker-green shadow-space"
      @click="reconnectToRoom"
    >
      {{ $t('games.game_over.of_course') }}
    </button>
    <button
      class="w-full bg-space-red text-center rounded-full border-none relative text-white text-xl font-bold px-4 py-2 transition duration-150 hover:bg-space-darker-red shadow-space"
      @click="disconnectToRoom"
    >
      {{ $t('games.game_over.no') }}
    </button>
  </div>
</template>

<script>
import {UPDATE_ROOM_STATE} from "../../../store/modules/userModule/actions";
import {ROUTE_ROOM_CONNECTION} from "../../../router";
import RoomConfigurationService from "../../../services/roomConfigurationService";
import {GAME_CONFIGURATION} from "../../../constants/games/GameStatusConstants";

export default {
  name: "EndButtons",
  methods: {
    reconnectToRoom() {
      // Set room state to null
      this.$store.dispatch(UPDATE_ROOM_STATE, GAME_CONFIGURATION);
      this.$router.push({name: ROUTE_ROOM_CONNECTION.name, query: {pin: this.$route.params.pin}});
    },

    disconnectToRoom() {
      RoomConfigurationService.disconnectRoom(
        this.$socket.client,
        this.$store.getters.getUser,
        this.$route.params.pin
      );
    }
  }
};
</script>

<style scoped>

</style>
