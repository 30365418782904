import {API} from "@/utils/api";
import i18n from "@/i18n/i18n";
import Vue from "vue";

class GameService {
  /**
     * Get a bundle
     * @param id
     * @returns {Bundle}
     */
  async getBundle(id) {
    return await API.get(`bundles/${id}`)
      .then((res) => res.data)
      .catch(() => {
        Vue.$toast.error(i18n.t('room.connection.errors.not_found'));
      });
  }

  /**
     * Validate exercise
     * @param currentExercise
     * @param code
     * @returns {object}
     */
  async validateExercise(currentExercise, code) {
    return await API.post(`exercises/validate`, {
      gameExerciseId: currentExercise.gameExercises.id,
      teamId: currentExercise.team.id,
      code: code,
    })
      .then((res) => res.data)
      .catch(() => { return { output: '', error: 'Une erreur est survenue' }});
  }

  /**
     * Get teams with gameId
     * @param gameId
     * @returns {Promise<*>}
     */
  async getTeamsFromGameId(gameId) {
    return await API.get('team', {params: {game: gameId}})
      .then((response) => response.data.sort((team1, team2) => team1.id - team2.id));
  }

  /**
     * Update User Team when he chooses a team
     * @param userId
     * @param teamId
     * @returns {Promise<*>}
     */
  async updateUserTeam(userId, teamId) {
    return await API.put(`/user/${userId}/me`, {
      team: teamId
    }).then(response => response.data);
  }

  /**
     * Create all relations when the owner start the game
     * @param gameId
     * @param teams
     * @returns {Promise<*>}
     */
  async createRelations(gameId, teams) {
    const usersId = teams.map(team => team.users.map(user => user.id));
    return await API.post('game-users', {
      gameId: gameId,
      teamsId: teams.map(team => team.id),
      usersId: usersId.flat()
    });
  }

  /**
     * Get exercises
     * @returns {Promise<*>}
     * @param teamId
     * @param withoutCode
     */
  async getStartedExercicesTeamId(teamId, withoutCode) {
    return await API.get('game-exercises/started-exercises', {params:
      {
        team: teamId,
        withoutCode: withoutCode
      }
    })
      .then(response => response.data);
  }

  getNextNotSolvedExercise(exercises) {
    return exercises.find(exercise => exercise.solvedAt === null);
  }

  exercisesCompleted(exercises) {
    return exercises.filter(exercise => exercise.solvedAt !== null).length;
  }

  async changeWinStatus(gameId, userId) {
    return await API.put('game-users/game-win-status', {
      user: userId,
      game: gameId
    });
  }

  async checkCountGameReward() {
    return await API.get('reward/count-game')
      .then(response => response.data);
  }

  async checkDeathCount() {
    return await API.get('reward/first-death')
      .then(response => response.data);
  }
}

export default new GameService();
