<template>
  <div class="h-screen" v-lazy-container="{ selector: 'img' }" ref="screen">
    <div class="w-full flex justify-center px-4 h-16 mt-4">
      <img :data-src="require('@/assets/images/SpaceCodeGreenLogo.png')">
    </div>
    <img
      :data-src="require('@/assets/images/Moon.svg')"
      class="opacity-70 w-1/3 top-0 relative z-10 ml-32"
    >
    <div class="w-full flex justify-center items-center -mt-96">
      <div class="flex relative z-30 justify-center">
        <Win />
      </div>
    </div>
  </div>
</template>

<script>
import Win from "../../components/Room/Win.vue";
import GameService from "../../services/gameService";
import {REWARDS} from "../../constants/dashboard/rewards";

export default {
  name: "RoomWin",
  components: { Win },
  data() {
    return {
      rewards: REWARDS
    };
  },
  mounted() {
    const user = this.$store.getters.getUser;

    if (user.is_guest) {
      return;
    }

    GameService.checkCountGameReward();
  }
};
</script>

<style scoped>
</style>
